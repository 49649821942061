import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image";
import ContactFormCom from '../../components/ContactFormCom';
import Offers from '../../components/Offers';
import SEO from '../../components/SEO';

const HeroStyledWrapper = styled.div`
  position: relative;
  height: 30vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: 100px;
`;

const HeroStyledHeaders = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

const HeroStyledHeader = styled.h1`
  position: relative;
  z-index: 9997;
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.font.size.m};
  font-family: 'Dancing Script', cursive;
  line-height: 2.2rem;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.xxxl};
  }
`;

const HeroStyledImage = styled(GatsbyImage)`
  position: relative;
  height: 30vh;
  width: 100%;
  opacity: 0.8;
`;

const TextStyledWrapper = styled.div`
  position: relative;
  text-align: center;
  margin: 50px;
  font-size: ${({ theme }) => theme.font.size.xs};

  ${({ theme }) => theme.mq.desktop} {
    margin: 50px 300px 0 300px;
    font-size: ${({ theme }) => theme.font.size.s};
  }
`;

const OffersWrapper = styled.div`
  background-color: ${({ theme }) => theme.primaryFloristic};
  padding: 2rem;
  margin: 2rem 0;
  text-align: center;
  position: relative;
`;

const StyledTitle = styled.h2`
  font-weight: 600;
  font-size: ${({ theme }) => theme.font.size.s};
  text-transform: uppercase;
  text-shadow: 1px 1px 2px lightgrey;
  font-family: 'Dancing Script', cursive;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

const StyledTitleOffers = styled(StyledTitle)`
  padding: 1.5rem 0 0 0;
  text-align: center;
`;

const DescriptionWrapper = styled.div`
  line-height: 2.2rem;
  max-width: 800px;
  margin: 2em auto;
`;

const StyledParagraph = styled.p`
  margin: 4rem 0;
`;

export default function FlorystykaOkolicznosciowa({ data }) {
  return (
    <>
      <SEO title="Florystyka okolicznościowa, Flower Box pudełko z kwiatami" />
      <HeroStyledWrapper>
        <HeroStyledHeaders>
          <HeroStyledHeader>{data.singleOffer.title}</HeroStyledHeader>
        </HeroStyledHeaders>
        <HeroStyledImage image={data.singleOffer.image.asset.gatsbyImageData} />
      </HeroStyledWrapper>
      <TextStyledWrapper>
        <DescriptionWrapper>
          <StyledParagraph>
            Jeżeli szukasz niebanalnego prezentu który wyróżni Ciebie a tym
            bardziej uszczęśliwi obdarowaną osobę, mamy dla Ciebie propozycję -
            <StyledParagraph>
              <b>"Flower Box – pudełko z kwiatami”</b>
            </StyledParagraph>
            Efektowny podarunek który łączy piękno kwiatów i dodatków wraz z
            nowoczesnym designem. Zaskoczy nie tylko tradycjonalistów lubiących
            klasykę.
          </StyledParagraph>
          <StyledParagraph>
            Każdy Flower Box tworzony jest z najwyższej jakości kwiatów i
            dodatków. Umieszczona w nim{' '}
            <b>gąbka z wodą zapewni roślinom stały dostęp do „pożywienia”</b>,
            dzięki czemu całość będzie cieszyła nasze oczy na długo. Może być w
            formie pudełka lub dekoracyjnej skrzyneczki drewnianej z samymi
            kwiatami albo w połączeniu z ulubionym trunkiem lub innym artykułem
            dekoracyjnym ( świeca zapachowa itp.).{' '}
          </StyledParagraph>
          <StyledParagraph>
            Idealnie wpasuję się jako{' '}
            <b>
              prezent na urodziny, imieniny, rocznicę ślubu lub jako dodatek
              kwiatowy dla Pary Młodej
            </b>
            . Śmiało można zastąpić nim nawet dekorację świąteczną postawioną na
            komodzie lub jako główny stroik na naszym wigilijnym stole.
            Stworzony z pachnących gałązek iglaków i mieniących się bombeczek
            nada świętom bajecznego klimatu.
          </StyledParagraph>
        </DescriptionWrapper>
      </TextStyledWrapper>
      <ContactFormCom />
      <OffersWrapper>
        <StyledTitleOffers>sprawdź nasze inne oferty</StyledTitleOffers>
        <Offers />
      </OffersWrapper>
    </>
  );
}

export const query = graphql`
  query FlorystykOkolicznosciowaQuery {
    singleOffer: sanityOffer(
      slug: { current: { eq: "florystyka-okolicznosciowa" } }
    ) {
      image {
        asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      id
      listOfOffers
      title
      headline
      description
    }
  }
`;
